import { Button, DateRangePicker, Link } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { useState, useMemo, useEffect, useCallback } from "react";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import { FaLockOpen } from "react-icons/fa6";
import { LockLog, LockLogRecordType } from "@/api/locks/locks.ts";
import { useAppSelector } from "@/hooks/useAppSelector";
import { UserChip } from "@/app/components/chips/userChip";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { ColumnType } from "@/app/components/tables/tableTypes";

export function LockLogsPage() {
  const { id } = useParams();
  const [data, setData] = useState<LockLog[]>([]);
  const handleError = useErrorHandling();

  const [activeDateFrom, setActiveDateFrom] = useState<string | null>(null);
  const [activeDateTo, setActiveDateTo] = useState<string | null>(null);
  const [activeDate, setActiveDate] = useState<any>(null);

  const offices = useAppSelector(state => state.handbooks.office);
  const office = useMemo(() => offices.find(office => office.id === Number(id)), [offices, id]);

  const resetFilters = useCallback(() => {
    setActiveDate(null);
    setActiveDateFrom(null);
    setActiveDateTo(null);
  }, []);

  useEffect(() => {
    api.locks.logs(Number(id), 0, 10, activeDateFrom!, activeDateTo!)
      .then(res => {
        setData(res.data);

      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [activeDateFrom, activeDateTo]);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex flex-col gap-2 max-md:flex-grow">
          <Link onClick={_e => { api.locks.unlock(office?.id || 0).then(_res => toast.success("Замок успешно открыт!")) }} className="flex flex-row gap-2 items-center p-4 rounded-xl font-medium text-foreground-600 cursor-pointer">
            <FaLockOpen />
            Открыть замок
          </Link>
          <Link href={`/dashboard/lock/${id}`} className="flex flex-row items-center p-4 rounded-xl font-medium text-primary bg-primary bg-opacity-10">
            Журнал действий
          </Link>
          <Link href={`/dashboard/lock/${id}/passcodes`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Пароли
          </Link>
        </div>
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row justify-between w-full gap-4 items-center">
                <span className="font-medium">
                  Журнал действий
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-2 items-center max-w-fit">
              <DateRangePicker
                label="Выберите отрезок времени"
                variant="bordered"
                onChange={e => {
                  setActiveDate(e);
                  setActiveDateFrom(`${e.start.year}-${e.start.month}-${e.start.day}`);
                  setActiveDateTo(`${e.end.year}-${e.end.month}-${e.end.day}`);
                }}
                value={activeDate}
              />
            </div>
            <div className="flex flex-row gap-2 max-w-fit">
              <Button size="sm" variant="flat" color="primary" onClick={resetFilters}>Сбросить</Button>
            </div>

            <TableBuilder
              removeWrapper
              displaySearch={false}
              displayOptions={false}
              defaultSortDescriptor={{
                column: "CREATED_AT",
                direction: "descending"
              }}
              columns={[
                {
                  type: ColumnType.Custom,
                  key: "user",
                  label: "Пользователь",
                  render(_value, row: LockLog) {
                    if(row.record_type == 3 || row.record_type == 12) {
                      if(row.admin_id) {
                        return "Администратор";
                      } else if(row.user_id) {
                        return <UserChip userId={row.user_id} />
                      } else {
                        return "Неизвестно";
                      }
                    }

                    return row.username;
                  }
                },
                {
                  type: ColumnType.Custom,
                  key: "record_type",
                  label: "Тип",
                  render(value, _row) {
                    switch(value) {
                      case LockLogRecordType.FINGERPRINT_UNLOCK:
                        return 'Отпечаток';
                      case LockLogRecordType.PASSCODE_UNLOCK:
                        return 'Пароль';
                      case LockLogRecordType.IC_CARD_UNLOCK:
                        return 'Карта';
                      case LockLogRecordType.GATEWAY_UNLOCK:
                      case LockLogRecordType.SECOND_GATEWAY_UNLOCK:
                        return 'Elastic';
                      default:
                        return 'Неизвестно';
                    }
                  },
                },
                {
                  type: ColumnType.DateTime,
                  key: "CREATED_AT",
                  label: "Дата и время",
                  sortable: true
                }
              ]}

              data={data || []}
              rowsPerPage={15}
              />
          </div>
        </div>
      </div>
    </>
  );
}
