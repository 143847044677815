import rootStore from "@/app/store";
import crud, { ApiLockLogResponse, ApiResponse } from "../crud"

export interface OfficeLock {
  id: number;
  display_name: string;
  location: {
    id: number;
    display_name: string;
  },
  shared: boolean;
}

export interface Passcode {
  id: number;
  lock_id: number;
  passcode: string;
}

export interface PasscodeUpdate {
  passcode: string;
}

export interface LockLog {
  id: number;
  lock_id: number;
  user_id: number;
  admin_id: number;
  record_type: number;
  CREATED_AT: string;
  message: string;
  username: string;
}

export type LockPasscode = Passcode;

export type LockLogResponse = LockLog[];

export enum LockLogRecordType {
  APP_UNLOCK = 1,
  PARKING_LOCK = 2,
  GATEWAY_UNLOCK = 3,
  PASSCODE_UNLOCK = 4,
  PARKING_LOCK_RAISE = 5,
  PARKING_LOCK_LOWER = 6,
  IC_CARD_UNLOCK = 7,
  FINGERPRINT_UNLOCK = 8,
  WRISTBAND_UNLOCK = 9,
  MECHA_KEY_UNLOCK = 10,
  BLUETOOTH_LOCK = 11,
  SECOND_GATEWAY_UNLOCK = 12,
  UNEXPECTED_UNLOCK = 29,
  DOOR_MAGNET_CLOSE = 30,
  DOOR_MAGNET_OPEN = 31,
  OPEN_FROM_INSIDE = 32,
  LOCK_BY_FINGERPRINT = 33,
  LOCK_BY_PASSCODE = 34,
  LOCK_BY_IC_CARD = 35,
  LOCK_BY_MECHA_KEY = 36,
  REMOTE_CONTROL = 37,
  TAMPER_ALERT = 44,
  AUTO_LOCK = 45,
  UNLOCK_BY_KEY = 46,
  LOCK_BY_KEY = 47,
  INVALID_PASSCODE = 48
};

export function LocksAPI(baseUrl: string) {
  return {
    list: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/locks/?renter_id=${renterId}`, undefined, true) as ApiResponse<OfficeLock[]>;
    },
    unlock: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/locks/${id}/unlock/?renter_id=${renterId}`, {}, true) as string;
    },
    createPasscode: async (lockData: Omit<Passcode, 'id'>) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/locks/${lockData.lock_id}/passcodes/?renter_id=${renterId}`, lockData, true) as ApiResponse<Lock>;
    },
    lock: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/locks/${id}/lock/?renter_id=${renterId}`, {}, true) as string;
    },
    logs: async (id: number, limit: number = 10, offset: number = 0, time_from: string, time_to: string) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/locks/${id}/logs/?offset=${offset}&limit=${limit}&time_from=${time_from}&time_to=${time_to}&renter_id=${renterId}`, undefined, true) as ApiLockLogResponse<LockLogResponse>;
    },
    passcodes: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/locks/${id}/passcodes/?renter_id=${renterId}`, undefined, true) as ApiResponse<Passcode[]>;
    },
    updatePasscode: async (id: number, passcodeId: number, params: PasscodeUpdate) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/locks/${id}/passcodes/${passcodeId}/?renter_id=${renterId}`, params, true) as string;
    },
    deletePasscode: async (id: number, passcodeId: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.delete(`${baseUrl}/locks/${id}/passcodes/${passcodeId}/?renter_id=${renterId}`, true) as string;
    }
  }
}