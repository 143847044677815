import { Button, Checkbox, Skeleton } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "@/api";
import useErrorHandling from "@/hooks/useErrorHandling";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { User } from "@/api/auth/auth.ts";
import { UserPermission } from "@/api/users/user.ts";
import { hasPermission } from "@/utils";

export function UserRolesEditPage() {
  const { id } = useParams();
  const [user, setUser] = useState<User | null>(null);
  const [permissions, setPermissions] = useState<UserPermission[] | null>(null);

  const navigate = useNavigate();
  const handleError = useErrorHandling();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      permissions: [] as number[],
    },
  });

  useEffect(() => {
    api.users.permissions.list()
      .then(res => {
        setPermissions(res.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [id]);

  useEffect(() => {
    api.users.get(Number(id))
      .then(res => {
        setUser(res.data);
        api.users.permissions.getUserPermissions(res.data.id)
          .then(res => {
            const defaultPermissions = permissions?.
              filter(permission => {
                return hasPermission(permission.bit, res.data);
              })
              .map(permission => {
                return permission.id
              }) || [];
            
            setValue('permissions', defaultPermissions);
          })
          .catch(err => {
            const { errorMessage } = handleError(err);
            toast.error(errorMessage);
          });
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [id, permissions]);

  const onSubmit = (data: { permissions: number[] }) => {
    api.users.permissions.updateUserPermissions(user?.id || 0, data.permissions)
      .then(_res => {
        toast.success("Права доступа успешно обновлены!");
        navigate("/dashboard/users");
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  };

  const handleDismissUser = () => {
    api.users.permissions.updateUserPermissions(user?.id || 0, [])
      .then(_res => {
        toast.success("Пользователь успешно уволен!");
        setValue('permissions', []);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  };

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row w-full justify-between gap-4 items-center">
                <span className="font-medium">Изменение прав пользователя</span>
                <div className="flex flex-row items-center gap-4">
                  <Button
                    size="sm"
                    variant="solid"
                    color="danger"
                    onClick={handleDismissUser}
                  >
                    Уволить
                  </Button>
                  <Button
                    size="sm"
                    variant="solid"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Сохранить
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-1 items-center">
              <span>Вы изменяете права пользователю</span>
              <Skeleton isLoaded={user !== null} className="rounded">{user?.first_name || 'Загрузка'} {user?.last_name || 'Загрузка'}</Skeleton>
            </div>
            <div className="flex flex-col gap-1">
              {permissions?.map((permission) => (
                <Controller
                  key={permission.id}
                  name="permissions"
                  control={control}
                  render={({ field }) => {
                    const isSelected = field.value.includes(permission.id);
                    return (
                      <div className="flex flex-row items-center gap-2">
                        <Checkbox
                          isSelected={isSelected}
                          onChange={() => {
                            const currentPermissions = field.value;
                            if (isSelected) {
                              field.onChange(currentPermissions.filter((id) => id !== permission.id));
                            } else {
                              field.onChange([...currentPermissions, permission.id]);
                            }
                          }}
                        >
                          {permission.display_name} ({permission.name})
                        </Checkbox>
                      </div>
                    );
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
