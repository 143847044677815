import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface Printer {
  printer_id: string;
  location_id: string;
  floor: number;
  enabled: boolean;
  id: number;
  name: string;
  features: {
    color: boolean;
    twoSided: boolean;
    pdf: boolean;
    jpeg: boolean;
    urf: boolean;
    pwg: boolean;
  }
}

export interface Job {
  id: number;
  printer_id: number;
  user_id: number;
  job_id: number;
  job_status: string;
  filename: string;
  created_at: string;
}

export function PrintersAPI(baseUrl: string) {
  return {
    list: async (location_id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/printers/${location_id}/printers?renter_id=${renterId}`, undefined, true) as ApiResponse<Printer[]>;
    },
    createJob: async (location_id: number, printer_id: number, double_sided: boolean, colored: boolean, file: File) => {
      const renterId = rootStore.getState().location.location.id;

      const formData = new FormData();
      formData.append('file', file);

      return await crud.post(`${baseUrl}/printers/print-job?renter_id=${renterId}&location_id=${location_id}&printer_id=${printer_id}&double_sided=${double_sided}&colored=${colored}`, formData, true, "auto") as ApiResponse<Job>;
    },
    checkJob: async (job_id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/printers/${job_id}?renter_id=${renterId}`, undefined, true) as ApiResponse<Job>;
    },
    getAvailablePrints: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/printers/available-prints?renter_id=${renterId}`, undefined, true) as ApiResponse<number>;
    }
  }
}