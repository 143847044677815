import { api } from "@/api";
import { OfficeLocation } from "@/api/locations/locations";
import { MeetingRoom, MeetingRoomRent } from "@/api/meetingRooms";
import { EditMeetingRoomRentModal } from "@/app/components/modals/meetingRooms/editMeetingRoomRent";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { ColumnType } from "@/app/components/tables/tableTypes";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useDisclosure } from "@nextui-org/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaPen, FaUser } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function MeetingRoomCard({ room }: { room: MeetingRoom }) {
  const navigate = useNavigate();
  const callback = useCallback(() => {
    navigate("/dashboard/meetingRooms/" + room.id)
  }, [navigate, room.id]);

  return (
    <div className="cursor-pointer flex flex-col rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4" onClick={callback}>
      <span>{room.display_name}</span>
      <span className="flex flex-row items-center gap-2 text-foreground text-opacity-80">
        {room.places}
        <FaUser className="text-xs" />
      </span>
      <span className="flex flex-row gap-2 text-sm uppercase font-semibold text-foreground text-opacity-80">
        {room.features.length >= 1 ? (
          <span>Есть {room.features.join(", ")}</span>
        ) : null}
        <span>{room.floor} этаж</span>
      </span>
    </div>
  );
}

export function MeetingRoomsPage() {
  const [locations, setLocations] = useState<OfficeLocation[] | null>(null);
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoom[]>([]);
  const [rents, setRents] = useState<MeetingRoomRent[] | null>(null);
  const [time, setTime] = useState<number | null>(0);

  const [activeRent, setActiveRent] = useState<MeetingRoomRent | null>(null);

  const editDisclosure = useDisclosure();

  const normalizedRents = useMemo(() => {
    return rents?.map(rent => {
      return {
        display_name: meetingRooms.find(mr => mr.id == rent.meeting_room_id)?.display_name,
        ...rent
      }
    });
  }, [rents, meetingRooms]);

  const handleError = useErrorHandling();

  useEffect(() => {
    api.meetingRooms.getRemainingTime()
      .then(res => {
        setTime(res.data.time);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });

    api.locations.list()
      .then(res => {
        setLocations(res.data);
        const rooms: Set<MeetingRoom> = new Set();

        const locationRequests = res.data.map(loc =>
          api.meetingRooms.list(loc.id)
            .then(res => {
              res.data.forEach(room => rooms.add(room));
            })
        );

        Promise.all(locationRequests)
          .then(() => {
            setMeetingRooms(Array.from(rooms));
          })
          .catch(err => {
            const { errorMessage } = handleError(err);
            toast.error(errorMessage);
          });

      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });

    api.meetingRooms.getCompanyBookings()
      .then(res => {
        setRents(res.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [handleError, editDisclosure.isOpen]);

  return (
    <>
      <EditMeetingRoomRentModal disclosure={editDisclosure} rent={activeRent} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row justify-between w-full gap-4 items-center">
                <span className="font-medium">
                  Переговорки
                </span>
              </div>
            </div>
            <span className="p-4 border-2 border-primary-500 rounded-xl max-w-fit">В этом месяце вы можете потратить на аренду ещё {time} часов</span>
            <div className="flex flex-col gap-2 w-full">
              {locations?.map(location => (
                <div className="flex flex-col gap-1 w-full" key={location.id}>
                  <span className="text-sm font-semibold uppercase text-foreground text-opacity-50">{location.display_name}</span>
                  <div className="flex flex-row flex-wrap gap-2">
                    {meetingRooms.filter(room => room.location_id === location.id).map(room => (
                      <MeetingRoomCard room={room} key={room.id} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full flex flex-col bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row justify-between w-full gap-4 items-center">
                <span className="font-medium">
                  Мои бронирования
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <TableBuilder
                removeWrapper
                displayOptions={false}
                displaySearch={false}
                columns={[
                  {
                    key: "display_name",
                    label: "Комната",
                    type: ColumnType.String
                  },
                  {
                    key: "user_id",
                    label: "Сотрудник",
                    type: ColumnType.User
                  },
                  {
                    key: "rent_from",
                    label: "Начало",
                    type: ColumnType.DateTime
                  },
                  {
                    key: "rent_to",
                    label: "Конец",
                    type: ColumnType.DateTime
                  },
                  {
                    key: "actions",
                    label: "Действия",
                    type: ColumnType.Actions,
                    actions: [
                      {
                        icon: <FaPen />,
                        onClick(_value, row) {
                          setActiveRent(row);
                          editDisclosure.onOpen();
                        },
                      }
                    ]
                  }
                ]}
                data={normalizedRents || []}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
