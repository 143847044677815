import { useDisclosure } from "@nextui-org/react";
import { ModalError } from "../formModalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { useState } from "react";
import useErrorHandling from "@/hooks/useErrorHandling";
import { CreateRenterParams, Renter } from "@/api/renters/renters.ts";
import { InputField, ModalBuilder } from "../modalBuilder";

const fields: InputField[] = [
  {
    label: "Название компании",
    name: "display_name",
    placeholder: "ООО ...",
    type: "text",
    pattern: /^[a-zA-Zа-яА-Я0-9"'\«\»\„\“\”\"\"\-_ ]+$/,
    patternMessage: "Введите корректное название компании",
    isRequired: true
  }, {
    label: "ОГРН/ОГРНИП",
    name: "orgn",
    placeholder: "Ваш ОГРН/ОГРНИП",
    type: "text",
    pattern: /^\d{13}|\d{15}$/,
    patternMessage: "Введите корректный ОГРН (13 цифр) или ОГРНИП (15 цифр)",
    isRequired: false
  }, {
    label: "Номер телефона",
    name: "phone_number",
    placeholder: "+7",
    pattern: /^\+7\d{10}$/,
    type: "phone",
    isRequired: true
  }, {
    label: "Адрес почты",
    name: "email",
    placeholder: "Адрес электронной почты",
    pattern:  /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    type: "text",
    isRequired: true
  }, {
    label: "Адрес",
    name: "address",
    placeholder: "Ваш юридический адрес",
    type: "text",
    isRequired: true
  }, {
    label: "ИНН",
    name: "inn",
    placeholder: "Ваш ИНН",
    type: "text",
    pattern: /^\d+$/,
    patternMessage: "Введите корректный ИНН",
    isRequired: true
  }
];

export function CreateRenterModal({ disclosure, renter }: { disclosure: ReturnType<typeof useDisclosure>, renter?: Partial<Renter> | null }) {
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  const handleError = useErrorHandling();
  
  const onSubmit = async (data: CreateRenterParams) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.renters.create(data)
        .then(_data => {
          toast.success("Компания успешно создана!");
          disclosure.onClose();

          api.requisites.create({
            renter_id: _data.data.id,
            address: _data.data.address,
            display_name: _data.data.display_name,
            email: _data.data.email,
            currency: 1,
            phone: _data.data.phone_number,
            inn: _data.data.inn,
            ogrn: Number(_data.data.orgn),
            kpp: 0,
            correspondent_account: "",
            bank_account: "",
            bank_name: "",
            bik: ""
          });

          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        })
    });
  }

  return (
    <ModalBuilder
      title="Создание компании"
      isOpen={disclosure.isOpen}
      modalErrors={modalErrors}
      onOpenChange={disclosure.onOpenChange}
      onSubmit={onSubmit}
      fields={fields}
      submitButtonText="Создать"
      isSensitiveData={false}
      defaultValues={{
        display_name: renter?.display_name || "",
        orgn: renter?.orgn || "",
        phone_number: renter?.phone_number || "",
        email: renter?.email || "",
        inn: renter?.inn || "",
      }}
    />
  )
}