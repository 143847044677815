import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud";

export interface MeetingRoom {
  id: number;
  location_id: number;
  display_name: string;
  picture_url: string;
  places: number;
  floor: number;
  features: string[];
}

export interface MeetingRoomRent {
  id: number;
  meeting_room_id: number;
  renter_id: number;
  user_id: number;
  rent_from: string;
  rent_to: string;
  created_at: string;
  updated_at: string;
}

export interface MeetingRoomRentCreate {
  meeting_room_id: number;
  renter_id: number;
  user_id: number;
  rent_from: string;
  rent_to: string;
}

export interface MeetingRoomRentUpdate {
  rent_from: string;
  rent_to: string;
}

export function MeetingRoomsAPI(baseUrl: string) {
  return {
    list: async (locationId: number) => {
      const renterId = rootStore.getState().location.location.id;
      return (await crud.get(`${baseUrl}/meeting-rooms/?renter_id=${renterId}&location_id=${locationId}`, undefined, true)) as ApiResponse<MeetingRoom[]>;
    },
    get: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return (await crud.get(`${baseUrl}/meeting-rooms/${id}/?renter_id=${renterId}`, undefined, true)) as ApiResponse<MeetingRoom>;
    },
    createRent: async (rentData: MeetingRoomRentCreate) => {
      const renterId = rootStore.getState().location.location.id;
      return (await crud.post(`${baseUrl}/meeting-rooms/rents?renter_id=${renterId}`, rentData, true)) as ApiResponse<MeetingRoomRent>;
    },
    getCompanyBookings: async () => {
      const renterId = rootStore.getState().location.location.id;
      return (await crud.get(`${baseUrl}/meeting-rooms/rents?renter_id=${renterId}&room_id=1`, undefined, true)) as ApiResponse<MeetingRoomRent[]>;
    },
    getAvailability: async (date: string, roomId: number) => {
      const renterId = rootStore.getState().location.location.id;
      return (await crud.get(`${baseUrl}/meeting-rooms/rents/availability?date=${date}&room_id=${roomId}&renter_id=${renterId}`, undefined, true)) as ApiResponse<{ rent_from: string; rent_to: string }[]>;
    },
    updateRent: async (rentId: number, rentData: MeetingRoomRentUpdate) => {
      const renterId = rootStore.getState().location.location.id;
      return (await crud.put(`${baseUrl}/meeting-rooms/rents/${rentId}?renter_id=${renterId}`, rentData, true)) as ApiResponse<{ message: string }>;
    },
    deleteRent: async (rentId: number) => {
      const renterId = rootStore.getState().location.location.id;
      return (await crud.delete(`${baseUrl}/meeting-rooms/rents/${rentId}?renter_id=${renterId}`, true)) as ApiResponse<{ message: string }>;
    },
    getRemainingTime: async () => {
      const renterId = rootStore.getState().location.location.id;
      return (await crud.get(`${baseUrl}/meeting-rooms/rents/time?renter_id=${renterId}`, undefined, true)) as ApiResponse<{ time: number }>;
    },
  };
}
