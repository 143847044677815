import { api } from "@/api";
import { MeetingRoom } from "@/api/meetingRooms";
import useErrorHandling from "@/hooks/useErrorHandling";
import { getNormalizedPydanticDate } from "@/utils";
import { Button, DatePicker } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { I18nProvider } from "@react-aria/i18n";
import { parseDate } from "@internationalized/date";
import { FaUser } from "react-icons/fa6";
import { useAppSelector } from "@/hooks/useAppSelector";
import ym from "react-yandex-metrika";

interface SelectionInterval {
  time_from: string;
  time_to: string;
}

interface AvailabilityInterval {
  rent_from: string;
  rent_to: string;
}

interface TimeSelectorProps {
  gap: number;
  startTime: string;
  endTime: string;
  loading: boolean;
  availability: AvailabilityInterval[];
  onSelect: (data: SelectionInterval) => void;
  resetTrigger: number;
  selectedDate: string;
}

interface TimeSlot {
  label: string;
  from: string;
  to: string;
  available: boolean;
  selected: boolean;
}

function TimeSelector(props: TimeSelectorProps) {
  const { gap, startTime, endTime, loading, availability, onSelect, resetTrigger, selectedDate } = props;
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [selection, setSelection] = useState<{ start: number | null; end: number | null }>({ start: null, end: null });
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const timeStringToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const minutesToTimeString = (minutes: number): string => {
    const hrs = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const pad = (n: number) => n.toString().padStart(2, '0');
    return `${pad(hrs)}:${pad(mins)}:00`;
  };

  const parseDateTime = (dateTimeStr: string): Date => {
    return new Date(dateTimeStr.replace(' ', 'T'));
  };

  const isDateBeforeToday = (dateStr: string): boolean => {
    const today = new Date();
    const selected = new Date(dateStr);
    today.setHours(0, 0, 0, 0);
    selected.setHours(0, 0, 0, 0);
    return selected.getTime() < today.getTime();
  };

  const isDateToday = (dateStr: string): boolean => {
    const today = new Date();
    const selected = new Date(dateStr);
    return selected.toDateString() === today.toDateString();
  };

  useEffect(() => {
    const slots: TimeSlot[] = [];
    const start = timeStringToMinutes(startTime);
    const end = timeStringToMinutes(endTime);

    for (let current = start; current < end; current += gap) {
      const from = minutesToTimeString(current);
      const to = minutesToTimeString(current + gap);
      const labelFrom = from.slice(0, 5);
      const labelTo = to.slice(0, 5);
      slots.push({
        label: `${labelFrom}-${labelTo}`,
        from,
        to,
        available: true,
        selected: false,
      });
    }

    // Обработка занятых интервалов
    availability.forEach((interval) => {
      const rentStartDate = parseDateTime(interval.rent_from);
      const rentEndDate = parseDateTime(interval.rent_to);
      const rentStart = rentStartDate.getHours() * 60 + rentStartDate.getMinutes();
      const rentEnd = rentEndDate.getHours() * 60 + rentEndDate.getMinutes();

      slots.forEach(slot => {
        const slotStart = timeStringToMinutes(slot.from.slice(0, 5));
        const slotEnd = timeStringToMinutes(slot.to.slice(0, 5));

        if (
          (slotStart >= rentStart && slotStart < rentEnd) ||
          (slotEnd > rentStart && slotEnd <= rentEnd) ||
          (slotStart <= rentStart && slotEnd >= rentEnd)
        ) {
          slot.available = false;
        }
      });
    });

    // Обработка прошедших интервалов
    if (isDateBeforeToday(selectedDate)) {
      // Если выбранная дата в прошлом, все слоты недоступны
      slots.forEach(slot => {
        slot.available = false;
      });
    } else if (isDateToday(selectedDate)) {
      const now = new Date();
      const currentMinutes = now.getHours() * 60 + now.getMinutes();
      slots.forEach(slot => {
        const slotEnd = timeStringToMinutes(slot.to.slice(0, 5));
        if (slotEnd <= currentMinutes) {
          slot.available = false;
        }
      });
    }

    setTimeSlots(slots);
    setSelection({ start: null, end: null });
    setHoverIndex(null);
  }, [gap, startTime, endTime, availability, resetTrigger, selectedDate]);

  const handleSlotClick = (index: number) => {
    if (loading) return;

    const clickedSlot = timeSlots[index];
    if (!clickedSlot.available) return;

    setSelection(prevSelection => {
      let newSelection = { ...prevSelection };

      if (prevSelection.start === null) {
        newSelection = { start: index, end: null };
        const updatedSlots = timeSlots.map((slot, idx) => ({
          ...slot,
          selected: idx === index,
        }));
        setTimeSlots(updatedSlots);
      } else if (prevSelection.start !== null && prevSelection.end === null) {
        newSelection.end = index;

        const start = Math.min(prevSelection.start, index);
        const end = Math.max(prevSelection.start, index);
        const selectedRange = timeSlots.slice(start, end + 1);
        const allAvailable = selectedRange.every(slot => slot.available);

        if (!allAvailable) {
          toast.error("Выбранный интервал уже занят! Пожалуйста, выберите другой.");
          newSelection = { start: null, end: null };
          const updatedSlots = timeSlots.map(slot => ({
            ...slot,
            selected: false,
          }));
          setTimeSlots(updatedSlots);
        } else {
          const updatedSlots = timeSlots.map((slot, idx) => ({
            ...slot,
            selected: idx >= start && idx <= end,
          }));
          setTimeSlots(updatedSlots);

          onSelect({
            time_from: updatedSlots[start].from,
            time_to: updatedSlots[end].to,
          });

          setHoverIndex(null);
        }
      } else {
        newSelection = { start: index, end: null };
        const updatedSlots = timeSlots.map((slot, idx) => ({
          ...slot,
          selected: idx === index,
        }));
        setTimeSlots(updatedSlots);
      }

      return newSelection;
    });
  };

  const handleMouseEnter = (index: number) => {
    if (selection.start === null) return;
    if (selection.end !== null) return;
    if (!timeSlots[index].available) return;
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const isInHoverRange = (index: number): boolean => {
    if (selection.start === null || hoverIndex === null) return false;
    const start = selection.start;
    const end = hoverIndex;
    if (start === end) return false;
    const min = Math.min(start, end);
    const max = Math.max(start, end);

    return index > min - 1 && index <= max && index < timeSlots.length;
  };

  return (
    <>
      {timeSlots.map((slot, index) => (
        <div
          key={index}
          className={`p-2 m-1 border-2 border-foreground-200 rounded-xl cursor-pointer text-center text-sm
            ${!slot.available ? 'text-gray-300 dark:text-gray-600 cursor-not-allowed' : ''}
            ${slot.selected ? 'bg-primary-500 text-white border-primary-500' : 'bg-white dark:bg-zinc-900'}
            ${isInHoverRange(index) && !slot.selected ? 'bg-foreground-300 dark:bg-zinc-700' : ''}
            transition-colors duration-200
          `}
          onClick={() => handleSlotClick(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          {slot.label}
        </div>
      ))}
    </>
  );
}

export function RentMeetingRoomPage() {
  const { id } = useParams();
  const [rawSelectedDate, setRawSelectedDate] = useState(parseDate(new Date().toISOString().split("T")[0]));
  const [selectedDate, setSelectedDate] = useState<string>(getNormalizedPydanticDate(new Date().toString()));
  const [meetingRoom, setMeetingRoom] = useState<MeetingRoom | null>(null);
  const [availability, setAvailability] = useState<AvailabilityInterval[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedInterval, setSelectedInterval] = useState<SelectionInterval | null>(null);
  const [resetTrigger, setResetTrigger] = useState<number>(0);
  const [called, setCalled] = useState<boolean>(false);

  const renterId = useAppSelector(state => state.location.location.id);
  const userId = useAppSelector(state => state.user.currentUser.id)!;

  const handleError = useErrorHandling();
  const navigate = useNavigate();

  useEffect(() => {
    if(called) return;

    ym('reachGoal', 'rent_meeting_room_start');
    setCalled(true);
  }, []);

  useEffect(() => {
    api.meetingRooms.get(Number(id))
      .then(res => {
        setMeetingRoom(res.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [id, handleError]);

  useEffect(() => {
    if (meetingRoom) {
      api.meetingRooms.getAvailability(selectedDate, meetingRoom.id)
        .then(res => {
          setAvailability(res.data);
        })
        .catch(err => {
          const { errorMessage } = handleError(err);
          toast.error(errorMessage);
        });
    }
  }, [meetingRoom, selectedDate, handleError]);

  const handleSelect = (data: SelectionInterval) => {
    setSelectedInterval(data);
  };

  const handleReset = () => {
    setSelectedInterval(null);
    setResetTrigger(prev => prev + 1);
  };

  const handleDateChange = (e: any) => {
    setRawSelectedDate(e);
    setSelectedDate(e.toString() + "T00:01:02.000Z")
  };

  const rentCallback = useCallback(() => {
    const rentFrom = `${rawSelectedDate.toString()}T${selectedInterval?.time_from}.000Z`;
    const rentTo = `${rawSelectedDate.toString()}T${selectedInterval?.time_to}.000Z`;

    setLoading(true);

    api.meetingRooms.createRent({
      meeting_room_id: meetingRoom?.id || 0,
      rent_from: rentFrom,
      rent_to: rentTo,
      renter_id: renterId,
      user_id: userId
    })
      .then(_ => {
        ym('reachGoal', 'meeting_room_rented');
        toast.success("Бронь успешно создана!");
        navigate("/dashboard/meetingRooms");
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [meetingRoom, selectedInterval, selectedDate, renterId, userId]);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row justify-between w-full gap-4 items-center">
                <span className="font-medium">
                  Аренда переговорки
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-4 max-w-fit">
              <I18nProvider locale="ru-RU">
                <DatePicker
                  variant="bordered"
                  onChange={handleDateChange}
                  value={rawSelectedDate}
                />
              </I18nProvider>
            </div>
            <div className="flex flex-col-reverse md:flex-row gap-4">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row flex-wrap gap-1">
                  <TimeSelector
                    availability={availability}
                    startTime="08:00"
                    endTime="23:00"
                    gap={30}
                    loading={loading}
                    onSelect={handleSelect}
                    resetTrigger={resetTrigger}
                    selectedDate={selectedDate}
                  />
                </div>
                <div className="flex flex-row flex-wrap items-center w-full justify-between px-4 py-2 border-2 border-foreground-200 rounded-xl">
                  {selectedInterval ? (
                    <>
                      <span>
                        Выбранный интервал: {selectedInterval.time_from.slice(0, 5)} - {selectedInterval.time_to.slice(0, 5)}
                      </span>
                      <Button variant="light" color="primary" onClick={handleReset}>
                        Сбросить
                      </Button>
                    </>
                  ) : (
                    <>
                      <span>Вы ещё не выбрали никакой временной период.</span>
                      <Button className="opacity-0" variant="light" color="primary" onClick={handleReset}>
                        Сбросить
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 flex-grow w-full">
                <span className="text-lg font-semibold">{meetingRoom?.display_name}</span>
                <div className="flex flex-row gap-1 items-center text-foreground-500">
                  <span>{meetingRoom?.places}</span>
                  <FaUser className="text-xs" />
                </div>
                {(meetingRoom?.features || []).length > 0 ? (
                  <span className="text-foregroud-500">
                    <span>Есть {meetingRoom?.features.join(", ")}</span>
                  </span>
                ) : null}
                <Button
                  className="w-full mt-2"
                  color="primary"
                  onClick={rentCallback}
                  isDisabled={!selectedInterval}
                  children={"Арендовать"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
