import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Layout } from "./layout/mainLayout";
import { DashboardLoader, IndexLoader } from "./loaders/mainLoaders";
import { LoginPage } from "./pages/auth";
import { DashboardHome } from "./pages/dashboard";
import { Provider } from "react-redux";
import rootStore from "./store";
import { AuthLayout } from "./layout/authLayout";
import 'react-toastify/dist/ReactToastify.css';
import { LocationsPage } from "./pages/dashboard/locations";
import { LocationOfficesPage } from "./pages/dashboard/locations/[id]/offices";
import { UsersPage } from "./pages/dashboard/users/users";
import { TestPage } from "./pages/dashboard/test";
import { BillingsPage } from "./pages/dashboard/billings";
import { RentersPage } from "./pages/dashboard/renters";
import { SelectLocation } from "./pages/dashboard/selectLocation";
import { OfficesPage } from "./pages/dashboard/offices";
import { AssistantPage } from "./pages/dashboard/assistant";
import { TicketsPage } from "./pages/dashboard/tickets";
import { TicketPage } from "./pages/dashboard/ticket/[id]";
import { UserViewPage } from "./components/pages/userPage";
import { SettingsPage } from "./pages/dashboard/settings";
import { MyProfilePage } from "./pages/dashboard/users/me";
import { UserRolesEditPage } from "./pages/dashboard/users/[id]/roles";
import { UserProfilePage } from "./pages/dashboard/users/[id]/[id]";
import { ErrorBoundaryPage } from "./layout/errorBoundary";
import { LockLogsPage } from "./pages/dashboard/lock/[id]";
import { LockPasscodesPage } from "./pages/dashboard/lock/passcodes";
import { RenterPage } from "./pages/dashboard/renters/[id]";
import { NewLoginPage } from "./pages/auth/login";
import { NewRegisterPage } from "./pages/auth/register";
import { NewResetPage } from "./pages/auth/reset/reset";
import { NewAuthHomePage } from "./pages/auth/index";
import { NewInviteHashPage } from "./pages/auth/invite/[hash]";
import { NewVerifyPage } from "./pages/auth/reset/verify";
import { PrivacyPolicyPage } from "./pages/auth/privacyPolicy";
import { MeetingRoomsPage } from "./pages/dashboard/meetingRooms";
import { RentMeetingRoomPage } from "./pages/dashboard/meetingRooms/[id]/[id]";
import { PrintPage } from "./pages/dashboard/printers";
import { RequisitesPage } from "./pages/dashboard/requisites";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    errorElement: <ErrorBoundaryPage />,
    children: [
      {
        path: "/",
        loader: IndexLoader,
        element: <LoginPage />
      },
      {
        path: "/invite/:hash",
        loader: IndexLoader,
        element: <NewInviteHashPage />
      }
    ]
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    errorElement: <ErrorBoundaryPage />,
    children: [
      {
        path: "/auth",
        loader: IndexLoader,
        element: <NewAuthHomePage />
      },
      {
        path: "/auth/login",
        loader: IndexLoader,
        element: <NewLoginPage />
      },
      {
        path: "/auth/register",
        loader: IndexLoader,
        element: <NewRegisterPage />
      },
      {
        path: "/auth/reset",
        loader: IndexLoader,
        element: <NewResetPage />
      },
      {
        path: "/auth/reset/:username",
        loader: IndexLoader,
        element: <NewVerifyPage />
      },
      {
        path: "/auth/privacy",
        loader: IndexLoader,
        element: <PrivacyPolicyPage />
      }
    ]
  },
  {
    path: "/dashboard",
    element: <Layout />,
    errorElement: <ErrorBoundaryPage />,
    loader: DashboardLoader,
    children: [
      { path: "/dashboard", element: <DashboardHome /> },
      { path: "/dashboard/locations", element: <LocationsPage /> },
      { path: "/dashboard/locations/:id/offices", element: <LocationOfficesPage /> },
      
      { path: "/dashboard/users", element: <UsersPage /> },
      { path: "/dashboard/users/:id", element: <UserProfilePage /> },
      { path: "/dashboard/users/:id/roles", element: <UserRolesEditPage /> },
      { path: "/dashboard/users/:id/edit", element: <UserViewPage /> },

      { path: "/dashboard/renters", element: <RentersPage /> },

      { path: "/dashboard/payments", element: <BillingsPage /> },

      { path: "/dashboard/test", element: <TestPage /> },

      { path: "/dashboard/selectLocation", element: <SelectLocation /> },

      { path: "/dashboard/offices", element: <OfficesPage /> },

      { path: "/dashboard/lock/:id", element: <LockLogsPage /> },
      { path: "/dashboard/lock/:id/passcodes", element: <LockPasscodesPage /> },

      { path: "/dashboard/assistant", element: <AssistantPage /> },

      { path: "/dashboard/me", element: <MyProfilePage /> },
      { path: "/dashboard/settings", element: <SettingsPage /> },

      { path: "/dashboard/tickets", element: <TicketsPage /> },
      { path: "/dashboard/tickets/:id", element: <TicketPage /> },

      { path: "/dashboard/renters/:id", element: <RenterPage /> },

      { path: "/dashboard/meetingRooms", element: <MeetingRoomsPage /> },
      { path: "/dashboard/meetingRooms/:id", element: <RentMeetingRoomPage /> },

      { path: "/dashboard/printers", element: <PrintPage /> },

      { path: "/dashboard/requisites", element: <RequisitesPage /> },
    ]
  }
]);

export function App() {
  return (
    <Provider store={rootStore}>
      <RouterProvider router={router} />
    </Provider>
  )
}