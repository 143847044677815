import { useDisclosure } from "@nextui-org/react";
import { ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { Renter } from "@/api/renters/renters.ts";
import { InputField } from "../../forms/formBuilder";

interface IEditLockForm {
  display_name: string;
  orgn: string;
  phone_number: string;
  email: string;
  address: string;
  inn?: string;
}

const editLockFields: InputField[] = [
  {
    label: "Название компании",
    name: "display_name",
    placeholder: "ООО ...",
    type: "text",
    pattern: /^[a-zA-Zа-яА-Я0-9"'\«\»\„\“\”\"\"\-_ ]+$/,
    patternMessage: "Введите корректное название компании",
    isRequired: true
  }, {
    label: "ОГРН/ОГРНИП",
    name: "orgn",
    placeholder: "Ваш ОГРН/ОГРНИП",
    type: "text",
    pattern: /^\d{13}|\d{15}$/,
    patternMessage: "Введите корректный ОГРН (13 цифр) или ОГРНИП (15 цифр)",
    isRequired: false
  }, {
    label: "Номер телефона",
    name: "phone_number",
    placeholder: "+7",
    pattern: /^\+7\d{10}$/,
    type: "phone",
    isRequired: true
  }, {
    label: "Адрес почты",
    name: "email",
    placeholder: "Адрес электронной почты",
    pattern:  /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    type: "text",
    isRequired: true
  }, {
    label: "Адрес",
    name: "address",
    placeholder: "Ваш юридический адрес",
    type: "text",
    isRequired: true
  }, {
    label: "ИНН",
    name: "inn",
    placeholder: "Ваш ИНН",
    type: "text",
    pattern: /^\d+$/,
    patternMessage: "Введите корректный ИНН",
    isRequired: true
  }
];

export function EditRenterModal({ disclosure, renter }: { disclosure: ReturnType<typeof useDisclosure>, renter: Renter | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: IEditLockForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.renters.update(renter!.id, {
        display_name: data.display_name,
        email: data.email,
        orgn: data.orgn,
        phone_number: data.phone_number,
        address: data.address,
        inn: Number(data.inn)
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  return (
    <ModalBuilder
      title="Редактирование арендатора"
      fields={editLockFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        display_name: renter?.display_name,
        email: renter?.email,
        orgn: renter?.orgn,
        phone_number: renter?.phone_number,
        address: renter?.address,
        inn: renter?.inn?.toString()
      }} />
  );
}