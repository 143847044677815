import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";

export interface Currency {
  id: number;
  display_name: string;
}

export function NewCurrencyHandbook(props: AutocompleteProps<Currency>) {
  const items: Currency[] = [
    { id: 1, display_name: 'Рубль' }
  ];

  return (
    <Autocomplete
      defaultItems={items}
      label="Валюта"
      placeholder="Выберите валюту"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={item.id}>
          {item.display_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}