import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Requisite } from "@/api/requisites/requisites.ts";
import { toast } from "react-toastify";
import { api } from "@/api";
import useErrorHandling from "@/hooks/useErrorHandling";
import { FaPlus, FaPencil } from "react-icons/fa6";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { ColumnType } from "@/app/components/tables/tableTypes";
import { CreateRequisiteModal } from "@/app/components/modals/requisites/createRequisiteModal";
import { EditRequisiteModal } from "@/app/components/modals/requisites/editRequisitesModal";

export function RequisitesPage() {
  const [requisites, setRequisites] = useState<Requisite[] | null>(null);
  const [requisite, setRequisite] = useState<Requisite | null>(null);

  const handleError = useErrorHandling();

  const createRequisiteModalDisclosure = useDisclosure();
  const editRequisiteModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editRequisite = (requisite: Requisite) => {
    setRequisite(requisite);
    editRequisiteModalDisclosure.onOpen();
  }

  useEffect(() => {
    api.requisites.list()
      .then(response => {
        setRequisites(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createRequisiteModalDisclosure.isOpen,
    editRequisiteModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateRequisiteModal disclosure={createRequisiteModalDisclosure} />
      <EditRequisiteModal disclosure={editRequisiteModalDisclosure} requisite={requisite} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between w-full items-center mb-4">
              <div className="flex flex-row gap-4 items-center">
                <span className="font-medium">Счета</span>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <Button
                  startContent={<FaPlus />}
                  color="primary"
                  size="sm"
                  variant="solid"
                  className="max-w-fit"
                  onClick={() => createRequisiteModalDisclosure.onOpen()}
                >
                  Создать счёт
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-2 mb-2">
              <TableBuilder
                displayOptions={false}
                removeWrapper={true}
                fullscreenDisclosure={fullscreenDisclosure}
                columns={[
                  {
                    label: "Название счета",
                    key: "display_name",
                    type: ColumnType.String,
                    sortable: true
                  },
                  {
                    label: "ИНН",
                    key: "inn",
                    type: ColumnType.String,
                    sortable: true
                  },
                  {
                    label: "КПП",
                    key: "kpp",
                    type: ColumnType.String,
                    sortable: true
                  },
                  {
                    label: "ОГРН",
                    key: "ogrn",
                    type: ColumnType.String,
                    sortable: true
                  },
                  {
                    label: "Счёт в банке",
                    key: "bank_account",
                    type: ColumnType.String,
                    sortable: true
                  },
                  {
                    label: "Название банка",
                    key: "bank_name",
                    type: ColumnType.String,
                    sortable: true
                  },
                  {
                    label: "БИК",
                    key: "bik",
                    type: ColumnType.String,
                    sortable: true
                  },
                  {
                    label: "Корр. счёт",
                    key: "correspondent_account",
                    type: ColumnType.String,
                    sortable: true
                  },
                  {
                    key: "actions",
                    label: "Действия",
                    type: ColumnType.Actions,
                    actions: [
                      {
                        icon: <FaPencil />,
                        onClick: (_value, row) => { editRequisite(row); }
                      }
                    ]
                  }
                ]}
                data={requisites || []}
                rowsPerPage={10}
                defaultSortDescriptor={{
                  column: "display_name",
                  direction: "ascending"
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}