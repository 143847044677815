import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { api } from "@/api";
import { toast } from "react-toastify";

interface ICreateRequisiteForm {
  display_name: string
  inn: number
  kpp: number
  ogrn: number
  bank_account: string
  bank_name: string
  bik: string
  correspondent_account: string
  address: string
  phone: string
  email: string
  currency: number
}

const createRequisiteFields: InputField[] = [
  {
    label: "Название",
    name: "display_name",
    placeholder: "Название счёта",
    type: "text",
    isRequired: true
  }, {
    label: "ИНН",
    name: "inn",
    placeholder: "1234567890",
    type: "text",
    isRequired: true
  }, {
    label: "КПП",
    name: "kpp",
    placeholder: "123456789",
    type: "text",
    isRequired: false
  }, {
    label: "ОГРН",
    name: "ogrn",
    placeholder: "1234567890123",
    type: "text",
    isRequired: false
  }, {
    label: "Расчетный счет",
    name: "bank_account",
    placeholder: "12345678901234567890",
    type: "text",
    isRequired: true
  }, {
    label: "Название банка",
    name: "bank_name",
    placeholder: "Название банка",
    type: "text",
    isRequired: true
  }, {
    label: "Валюта",
    name: "currency",
    placeholder: "Валюта",
    type: "currency",
    isRequired: true
  }, {
    label: "БИК",
    name: "bik",
    placeholder: "123456789",
    type: "text",
    isRequired: true
  }, {
    label: "Корреспондентский счет",
    name: "correspondent_account",
    placeholder: "12345678901234567890",
    type: "text",
    isRequired: true
  }, {
    label: "Адрес",
    name: "address",
    placeholder: "Адрес компании",
    type: "text",
    isRequired: true
  }, {
    label: "Телефон",
    name: "phone",
    placeholder: "+71234567890",
    pattern: /^\+7\d{10}$/,
    type: "text",
    isRequired: true
  }, {
    label: "Email",
    name: "email",
    placeholder: "email@domain.com",
    pattern:  /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    type: "text",
    isRequired: true
  }
];

export function CreateRequisiteModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  const renterId = useAppSelector(state => state.location.location.id);

  const handleError = useErrorHandling();

  const onSubmit = (data: ICreateRequisiteForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {

      api.requisites
        .create(Object.assign({ renter_id: renterId }, data))
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание счёта" modalErrors={modalErrors} fields={createRequisiteFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}