import { api } from "@/api";

import {
  setTicketCategories,
  setLocations,
  setOffices,
  setRenters,
  setUsers,
  setRequisites,
} from "@/app/store/handbooks/reducer";

type HandbookMapEntry = {
  apiCall: (args?: any) => Promise<any>;
  action: (data: any) => any;
};

export type Handbook =
  | "ticketCategories"
  | "locations"
  | "offices"
  | "renters"
  | "users"
  | "requisites";

  export const handbookMap: Record<Handbook, HandbookMapEntry> = {
    ticketCategories: {
      apiCall: () => api.tickets.categories.list(),
      action: setTicketCategories,
    },
    locations: {
      apiCall: () => api.locations.list(),
      action: setLocations,
    },
    renters: {
      apiCall: () => api.renters.list(),
      action: setRenters,
    },
    users: {
      apiCall: () => api.renters.listEmployees(),
      action: setUsers,
    },
    offices: {
      apiCall: () => api.officies.list(),
      action: setOffices
    },
    requisites: {
      apiCall: () => api.requisites.list(),
      action: setRequisites
    }
  };