import { useEffect, useState } from "react";
import { FaArrowsRotate, FaCheck } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { Button, Checkbox, Tab, Tabs } from "@nextui-org/react";
import { Job, Printer } from "@/api/printers/printers";
import { api } from "@/api";
import { toast } from "react-toastify";
import { OfficeLocation } from "@/api/locations/locations";
import { Controller, useForm } from "react-hook-form";
import FileUpload from "@/app/components/fileUpload/fileUpload";

interface UploadForm {
  file: File;
  doubleSided: boolean;
  colored: boolean;
}

function PrinterSelector({ printer, onSelect, isSelected }: { printer: Printer, onSelect: (printer: Printer) => void, isSelected: boolean }) {
  return (
    <div
      className={`flex flex-col px-4 py-3 border-2 ${isSelected ? "border-primary" : "border-foreground-200"} rounded-xl cursor-pointer transition-all`}
      onClick={() => { printer.enabled && onSelect(printer) }}>
      <span>{printer.name}</span>
      {printer.enabled ? (
        <div className="flex flex-row gap-2 items-center text-primary">
          <FaCheck />
          <span className="font-normal">В сети</span>
        </div>
      ) : (
        <div className="flex flex-row gap-2 items-center text-foreground-400">
          <FaTimes />
          <span className="font-normal">Не в сети</span>
        </div>
      )}
    </div>
  )
}

export function PrintPage() {
  const [locations, setLocations] = useState<OfficeLocation[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [printers, setPrinters] = useState<Printer[]>([]);
  const [selectedPrinter, setPrinter] = useState<Printer | null>(null);
  const [stage, setStage] = useState<"select" | "upload" | "check">("select");
  const [job, setJob] = useState<Job | null>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);

  const [availablePrints, setAvailablePrints] = useState<number>(0);

  const uploadForm = useForm<UploadForm>({
    defaultValues: {
      doubleSided: false,
      colored: false
    }
  });

  useEffect(() => {
    api.locations.list()
      .then(res => {
        setLocations(res.data);
        setSelectedLocation(res.data[0].id);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке локаций!");
      });

    api.printers.getAvailablePrints()
      .then(res => {
        setAvailablePrints(res.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке баланса печатей!");
      });
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      api.printers.list(selectedLocation)
        .then(res => {
          setPrinters(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (stage === "check") {
      const interval = setInterval(() => {
        if (
          !isChecking &&
          job &&
          job.job_status !== "completed" &&
          job.job_status !== "cancelled" &&
          job.job_status !== "failed" &&
          job.job_status !== "aborted"
        ) {
          setIsChecking(true); 
          api.printers
            .checkJob(job.id)
            .then((res) => {
              setJob(res.data);

              if (res.data.job_status === "failed") {
                clearInterval(interval);
                toast.error("Произошла ошибка при печати!");
              }

              if (res.data.job_status === "cancelled" || res.data.job_status === "aborted") {
                clearInterval(interval);
                toast.error("Задача была отменена!");
              }

              if (res.data.job_status === "completed") {
                clearInterval(interval);
                toast.success("Задача завершена!");
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setIsChecking(false);
            });
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [stage, job, isChecking]);

  const handleUpload = (data: UploadForm) => {
    if (!data.file) {
      toast.error("Файл не выбран!");
      return;
    }

    setIsSubmitting(true);

    // @ts-ignore
    api.printers.createJob(selectedLocation, selectedPrinter?.id || 0, data.doubleSided, data.colored, data.file)
      .then(res => {
        setJob(res.data);
        setStage("check");
        setIsSubmitting(false);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке файла!");
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div className="flex flex-col w-full h-full justify-center items-center">
        <div className="bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4 w-full max-w-xl flex flex-col gap-1">
          <h1 className="text-lg font-semibold">Печать</h1>
          {stage === "select" && (
            <>
              <span className="text-sm">Доступно страниц: {availablePrints}</span>
              {locations.length > 0 && (
                <Tabs color="primary" selectedKey={selectedLocation} onSelectionChange={setSelectedLocation}>
                  {locations.map(location => (
                    <Tab key={location.id} title={location.display_name} />
                  ))}
                </Tabs>
              )}
              {selectedLocation && (
                <div className="mt-2 flex flex-col gap-2">
                  {Array.from({ length: 4 }).map((_, i) => (
                    <div className="flex flex-col gap-1" key={i}>
                      <span className="font-semibold text-sm text-foreground-500 uppercase">{i + 1} этаж</span>
                      <div className="flex flex-row gap-1">
                        {printers.filter(printer => printer.floor == i + 1).map(printer => (
                          <PrinterSelector key={printer.id} printer={printer} onSelect={setPrinter} isSelected={selectedPrinter === printer} />
                        ))}
                        {printers.filter(printer => printer.floor == i + 1).length == 0 && (
                          <span className="text-foreground-500 text-sm">На этом этаже нет доступных принтеров.</span>
                        )}
                      </div>
                    </div>
                  ))}

                  <Button
                    color="primary"
                    isDisabled={!selectedPrinter}
                    className="mt-1"
                    onClick={() => setStage("upload")}>
                    Продолжить
                  </Button>
                </div>
              )}
            </>
          )}
          {stage === "upload" && (
            <>
              <form className="flex flex-col gap-1" onSubmit={uploadForm.handleSubmit(handleUpload)}>
                <span className="text-sm">Вы выбрали {selectedPrinter?.name} ({selectedPrinter?.floor} этаж)</span>
                {['color', 'twoSided'].map((key, i) => (
                  <Controller
                    key={i}
                    name={key == "color" ? "colored" : "doubleSided"}
                    control={uploadForm.control}
                    render={({ field }) => (
                      <Checkbox
                        isSelected={field.value}
                        onValueChange={field.onChange}
                        isDisabled={key == "color" ? !selectedPrinter?.features.color : !selectedPrinter?.features.twoSided}
                      >
                        {key == "color" ? "Цветная печать" : "Двусторонняя печать"}
                      </Checkbox>
                    )}
                  />
                ))}
                <FileUpload
                  control={uploadForm.control}
                  name="file"
                  accept="application/pdf"
                />

                <Button
                  color="primary"
                  className="mt-2"
                  isDisabled={isSubmitting}
                  type="submit">
                  Печать
                </Button>
              </form>
            </>
          )}
          {stage === "check" && (
            <>
              <div className="flex flex-row gap-2">
                {job?.job_status == "completed" ? (
                  <>
                    <div className={`flex flex-col items-center justify-center min-w-8 min-h-8 rounded-lg bg-primary text-primary bg-opacity-30 max-h-fit`}>
                      <FaCheck />
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="font-semibold">Ваша задача завершена</span>
                      <span>Вы можете забрать свой документ</span>
                    </div>
                  </>
                ) : job?.job_status == "processing" || job?.job_status == "pending" ? (
                  <>
                    <div className={`flex flex-col items-center justify-center min-w-8 min-h-8 rounded-lg bg-primary text-primary bg-opacity-30 max-h-fit`}>
                      <FaArrowsRotate />
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="font-semibold">Ваша задача в процессе печати</span>
                      <span>Мы будем обновлять эту страницу, чтобы отображать вам актуальный статус задачи</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={`flex flex-col items-center justify-center min-w-8 min-h-8 rounded-lg bg-primary text-primary bg-opacity-30 max-h-fit`}>
                      <FaTimes />
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="font-semibold">Печать не удалась</span>
                      <span>С баланса вашей компании не будут списаны страницы</span>
                    </div>
                  </>
                )}
              </div>
              <Button className="max-w-fit mt-2" color="primary" onClick={() => location.reload()}>Новая печать</Button>
            </>
          )}
        </div>
      </div >
    </>
  )
}