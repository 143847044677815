import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDisclosure } from "@nextui-org/react";
import { api } from "@/api";
import { Billing } from "@/api/billings/billings.ts";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { ColumnType } from "@/app/components/tables/tableTypes";
import { FaDownload } from "react-icons/fa6";
import { getBaseUrl } from "@/utils";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useAppSelector } from "@/hooks/useAppSelector";

export function BillingsPage() {
  const [billings, setBillings] = useState<Billing[]>([]);
  const handleError = useErrorHandling();

  const renter = useAppSelector(state => state.location.location);
  
  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.billings.list()
      .then(data => {
        setBillings(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке платежей!");
      });
  }, []);

  const previewBill = (bill_id: number) => {
    fetch(getBaseUrl() + `/client/billings/preview/${bill_id}?`
      + new URLSearchParams({
        renter_id: String(renter.id)
      }), {
      method: "POST",
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("access_token")
      }
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${renter.display_name}-${bill_id}-bill-${Math.round(Date.now() / 1000)}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        toast.success("Счёт успешно экспортирован!");
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  };

  return (
    <div className="flex flex-row flex-wrap gap-4 w-full">
      <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-medium">Платежи</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            removeWrapper
            displayOptions={false}
            columns={[
              {
                label: "Реквизиты",
                key: "clientProvider",
                type: ColumnType.Requisite,
                sortable: true
              },
              {
                label: "Сумма",
                key: "amount",
                type: ColumnType.Number,
                sortable: true
              },
              {
                label: "Статус",
                key: "paid",
                type: ColumnType.Boolean,
                sortable: true
              },
              {
                label: "Плательщик",
                key: "payer",
                type: ColumnType.Renter,
                sortable: true
              },
              {
                label: "Дата",
                key: "date",
                type: ColumnType.Date,
                sortable: true
              },
              {
                label: "Действия",
                key: "actions",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaDownload />, onClick(_value, row) { previewBill(row.id) },
                  }
                ]
              }
            ]}
            data={billings}
            rowsPerPage={10}
          />
        </div>
      </div>
    </div>
  )
}
