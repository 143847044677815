import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';
import { FaFile, FaHandPointer } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";

interface FileUploadProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  accept?: string;
}

const FileUpload = <TFieldValues extends FieldValues>({
  control,
  name,
  accept = '*/*',
}: FileUploadProps<TFieldValues>) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], field: { onChange: (files: File) => void }) => {
      if (acceptedFiles.length > 0) {
        field.onChange(acceptedFiles[0]);
      }
    },
    []
  );

  const removeFile = (field: { onChange: (files: File[] | null) => void }) => {
    field.onChange(null);
  };

  const parseAccept = (accept: string) => {
    if (accept === '*/*') {
      return {};
    }

    const mimeTypes = accept.split(',').reduce((acc: Record<string, string[]>, type) => {
      acc[type.trim()] = [];
      return acc;
    }, {});

    return mimeTypes;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
          onDrop: (acceptedFiles) => onDrop(acceptedFiles, field),
          accept: parseAccept(accept),
          multiple: false,
          noClick: true,
        });

        const hasFile = field.value;

        return (
          <div className="w-full">
            <div
              {...getRootProps()}
              className={`select-none cursor-pointer flex flex-col gap-4 mt-2 border-2 rounded-xl items-center px-4 py-8 ${
                hasFile
                  ? 'border-solid border-primary'
                  : 'border-dashed border-primary border-opacity-50'
              }`}
              onClick={open}
            >
              <input {...getInputProps()} />
              {hasFile ? (
                <div className="flex flex-col gap-2 items-center">
                  <FaFile />
                  <span className="text-sm font-medium">{field.value.name}</span>
                  <span className="text-xs text-gray-500">
                    {(field.value.size / 1024).toFixed(2)} KB
                  </span>
                  <button
                    type="button"
                    className="text-red-500 mt-2"
                    onClick={() => removeFile(field)}
                  >
                    <FaTimes />
                  </button>
                </div>
              ) : (
                <div className="flex flex-col gap-4 items-center">
                  <FaFile />
                  <span className="text-sm">
                    {isDragActive ? "Отпустите файл здесь..." : "Перетащите файл"}
                  </span>
                  <div className="flex flex-row gap-8 p-2 items-center w-full justify-center">
                    <div className="block w-full border-solid border-b-1 border-default max-w-[100px]"></div>
                    <span className="text-sm">или</span>
                    <div className="block w-full border-solid border-b-1 border-default max-w-[100px]"></div>
                  </div>
                  <div className="flex flex-col gap-4 items-center">
                    <FaHandPointer />
                    <span className="text-sm">Нажмите, чтобы выбрать файл</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default FileUpload;
