import { NewCategoryHandbook } from "../handbooks/categoryHandbook";
import { NewOfficeHandbook } from "../handbooks/officeHandbook";
import { NewRenterHandbook } from "../handbooks/renterHandbook";
import { NewCurrencyHandbook } from "../handbooks/service/currencyHandbook";
import { NewUserRoleHandbook } from "../handbooks/userRoleHandbook";

export type InputType =
  | "text"
  | "password"
  | "email"
  | "datetime-local"
  | "textarea"
  | "phone"
  | "user-role"
  | "renter"
  | "category"
  | "office"
  | "boolean"
  | "datepicker"
  | "currency";

export const handbookMap: Record<string, React.ComponentType<any>> = {
  office: NewOfficeHandbook,
  renter: NewRenterHandbook,
  "user-role": NewUserRoleHandbook,
  category: NewCategoryHandbook,
  currency: NewCurrencyHandbook
};