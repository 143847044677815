import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaArrowRightToBracket, FaLock, FaLockOpen } from "react-icons/fa6";
import { useDisclosure } from "@nextui-org/react";
import { api } from "@/api";
import { Office } from "@/api/officies/officies.ts";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { ColumnType } from "@/app/components/tables/tableTypes";
import { useNavigate } from "react-router-dom";

export function OfficesPage() {
  const navigate = useNavigate();
  const [offices, setOffices] = useState<Office[]>([]);
  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.officies.list()
      .then(async response => {
        return setOffices(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке офисов!");
      });
  }, []);

  const openLock = useCallback((id: number) => {
    api.locks.unlock(id)
      .then(data => {
        console.log(data);
        toast.success("Замок был успешно открыт!");
      })
      .catch(err => {
        console.log(err);
        toast.error("При открытии замка произошла ошибка!");
      });
  }, []);

  const closeLock = useCallback((id: number) => {
    api.locks.lock(id)
      .then(data => {
        console.log(data);
        toast.success("Замок был успешно закрыт!");
      })
      .catch(err => {
        console.log(err);
        toast.error("При закрытии замка произошла ошибка!");
      });
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-4 w-full">
      <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-medium">Офисы</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены доступные офисы в выбранной ранее локации.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            removeWrapper
            displayOptions={false}
            columns={[
              {
                label: "Название",
                key: "display_name",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Тип офиса",
                key: "office_type",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Площадь",
                key: "area",
                type: ColumnType.Number,
                sortable: true
              },
              {
                label: "Цена",
                key: "price",
                type: ColumnType.Number,
                sortable: true
              },
              {
                label: "Кол-во комнат",
                key: "room_count",
                type: ColumnType.Number,
                sortable: true
              },
              {
                label: "Рабочие места",
                key: "workplace_count",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Локация",
                key: "location",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Этаж",
                key: "floor",
                type: ColumnType.Number,
                sortable: true
              },
              {
                key: "action",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaLockOpen />,
                    onClick: (_value, row) => { openLock(row.id) }
                  },
                  {
                    icon: <FaLock />,
                    onClick: (_value, row) => { closeLock(row.id) }
                  },
                  {
                    icon: <FaArrowRightToBracket />,
                    onClick(_value, row) {
                      navigate("/dashboard/lock/" + row.office_lock)
                    },
                  }
                ]
              }
            ]}
            data={offices}
          />
        </div>
      </div>
    </div>
  )
}
